





































import axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class FeedbackForm extends Vue {
  show = false;

  email = '';

  message = '';

  isValid = false;

  readonly emailRules = [
    (n: string): boolean => n !== '',
    (n: string): boolean => /.+@.+/.test(n),
  ];

  readonly messageRules = [
    (n: string): boolean => n !== '',
  ];

  async submit(): Promise<void> {
    await axios({
      method: 'POST',
      url: 'https://formspree.io/f/mnqlvgpj',
      data: {
        email: this.email,
        message: this.message,
      },
    });
    this.show = false;
  }

  toggle(): void {
    this.show = !this.show;
  }
}
