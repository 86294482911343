import $ from 'jquery';

export default class Target {
  $el: JQuery<HTMLElement>;

  el: HTMLElement;

  tag: string;

  constructor(el: HTMLElement) {
    this.el = el;
    this.$el = $(el);
    this.tag = el.tagName.toLowerCase();
  }
}
