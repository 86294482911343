import Vue from 'vue';
import { Plugin } from 'vue-fragment';
import Vuetify from 'vuetify/lib/framework';

import App from './components/App.vue';

const vuetify = new Vuetify();

Vue.config.productionTip = false;
Vue.use(Plugin);
Vue.use(Vuetify);

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
