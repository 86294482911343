




import { position as caretPosition } from 'caret-pos';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { preview } from '../lib/util';

@Component
export default class Preview extends Vue {
  @Prop(Boolean) readonly editable!: boolean;

  @Prop(String) readonly value!: string;

  get iframe(): HTMLIFrameElement {
    const { iframe } = this.$refs as { iframe: HTMLIFrameElement };
    return iframe;
  }

  get contentDocument(): Document {
    return this.iframe.contentDocument as Document;
  }

  get body(): HTMLElement {
    return this.contentDocument.body;
  }

  mounted(): void {
    this.body.style.fontFamily = 'monospace';

    this.contentDocument.addEventListener('copy', (event: Event) => {
      this.$emit('copy', event);
    });

    if (this.editable) {
      this.contentDocument.designMode = 'on';
      this.body.contentEditable = 'true';

      this.contentDocument.addEventListener('input', (event: Event) => {
        event.preventDefault();
        this.$emit('input', this.body.innerHTML);
      });
    } else {
      this.body.style.userSelect = 'all';
    }
  }

  @Watch('value')
  watchValue(value: string): void {
    let position;
    const caretOptions = {
      iframe: this.iframe,
    };

    try {
      position = caretPosition(
        this.body,
        caretOptions,
      );
    // eslint-disable-next-line no-empty
    } catch {}

    this.body.innerHTML = preview(value);

    if (position) {
      caretPosition(
        this.body,
        position.pos,
        caretOptions,
      );
    }
  }
}
