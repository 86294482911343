





























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Options } from '../lib/interface';

@Component
export default class OptionsForm extends Vue {
  @Prop() readonly value!: Options;

  @Watch('value')
  onValueChanged(value: Options): void {
    this.$emit('input', value);
  }
}
