import $ from 'jquery';

import Target from './Target';

const NULL_PARENT_ERROR = new Error('element\'s parent is null');

export const applyRecursive = (
  parent: HTMLElement,
  fn: (child: HTMLElement) => void,
): void => {
  $(parent).children().each((i, child) => applyRecursive(child, fn));
  fn(parent);
};

export const getImageDimension = (
  el: HTMLElement,
  dimension: string,
): string | null => {
  let n = el.getAttribute(dimension);
  if (n === null) {
    const cssValue = el.style.getPropertyValue(dimension);
    if (cssValue !== '') {
      const int = Number.parseInt(cssValue, 10);
      n = String(int);
    }
  }
  return n;
};

export const getParent = (el: HTMLElement): HTMLElement => {
  const parent = el.parentElement;
  if (parent === null) {
    throw NULL_PARENT_ERROR;
  }
  return parent;
};

export const jQueryToString = ($n: JQuery): string => {
  let string = '';
  $n.each((i, el) => {
    string += el.outerHTML;
  });
  return string;
};

export const preview = (input: string): string => {
  const data = $(input).css('position', '');
  return jQueryToString(data);
};

export const extractElement = ($el: JQuery<HTMLElement>): void => {
  const children = $el.children();
  if (children.length > 0) {
    children.unwrap();
  } else {
    $el.remove();
  }
};

export const removeWhitespace = (n: string): string => n.replace(/\s+/g, '');

export const replace = ($el: JQuery<HTMLElement>, html: string): Target => {
  const replaced = $(html);
  const { attributes } = $el.get(0);
  $el.replaceWith(replaced);
  for (let i = 0; i < attributes.length; i += 1) {
    const { name, value } = attributes[i];
    if (name !== 'href') {
      try {
        replaced.attr(name, value);
      // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }
  return new Target(replaced.get(0));
};

export const wrapInList = (li: HTMLLIElement): HTMLUListElement => {
  const ul = document.createElement('ul');
  ul.appendChild(li);
  return ul;
};

export const wrapInner = (
  $el: JQuery<HTMLElement>,
  tagName: string,
): JQuery<HTMLElement> => $el.wrapInner(`<${tagName} />`);
