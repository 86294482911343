import { getParent, wrapInList } from './util';

const PREV_PARENT_ERROR = new Error('previousParent is undefined');

export default class MsoListConverter {
  previousParent: HTMLElement | null = null;

  prevMargin = 0;

  minMarginDelta = Infinity;

  getPreviousParent(): HTMLElement {
    if (this.previousParent === null) {
      throw PREV_PARENT_ERROR;
    }
    return this.previousParent;
  }

  calculateIndents(margin: number): number {
    const marginDelta = Math.abs(margin - this.prevMargin);
    if (margin !== this.prevMargin) {
      this.minMarginDelta = Math.min(
        marginDelta,
        this.minMarginDelta,
      );
    }
    return Math.ceil(marginDelta / this.minMarginDelta);
  }

  appendRightIndent(li: HTMLLIElement, indents: number): void {
    let ul = wrapInList(li);

    // Each extra indent adds a nested list
    for (let j = 1; j < indents; j += 1) {
      const parentLi = document.createElement('li');
      parentLi.appendChild(ul);
      ul = wrapInList(parentLi);
    }
    this.getPreviousParent().appendChild(ul);
  }

  appendLeftIndent(li: HTMLLIElement, indents: number): void {
    let targetParent = getParent(this.getPreviousParent());
    for (let j = 0; j < indents; j += 1) {
      targetParent = getParent(getParent(targetParent));
    }
    targetParent.appendChild(li);
  }

  appendNoIndent(li: HTMLLIElement): void {
    const previousParent = this.getPreviousParent();
    const parent = getParent(previousParent);
    parent.appendChild(li);
  }

  convert($el: JQuery<HTMLElement>): void {
    // Establish margin and indents
    const marginString = $el.get(0).style.marginLeft;
    const margin = Number.parseFloat(marginString);
    const indents = this.calculateIndents(margin);

    // Remove the first span, which is the manual bullet
    $el.find('span:first-child').remove();

    // Wrap content in list item
    const li = document.createElement('li');
    li.innerHTML = $el.html();

    // If first list item
    if ($el.hasClass('MsoListParagraphCxSpFirst')) {
      const ul = wrapInList(li);
      $el.replaceWith(ul);
    } else {
      // Remove existing element
      $el.remove();

      // If indented right
      if (margin > this.prevMargin) {
        this.appendRightIndent(li, indents);

      // If indented left
      } else if (margin < this.prevMargin) {
        this.appendLeftIndent(li, indents);

      // No new indent
      } else {
        this.appendNoIndent(li);
      }
    }

    // Track values for further iterations
    this.prevMargin = margin;
    this.previousParent = $el.hasClass('MsoListParagraphCxSpLast') ? null : li;
  }
}
