




import pretty from 'pretty';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class CodeBlock extends Vue {
  @Prop(String) readonly code!: string;

  get formatted(): string {
    return pretty(this.code);
  }
}
