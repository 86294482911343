import $ from 'jquery';

import MsoListConverter from './MsoListConverter';
import Target from './Target';
import { Options } from './interface';

import {
  applyRecursive,
  extractElement,
  getImageDimension,
  jQueryToString,
  replace,
  wrapInner,
} from './util';

const ATTR_WHITELIST = [
  'href',
  'src',
];

const TAG_BLACKLIST = [
  'meta',
  'button',
];

const MSO_NAMES = [
  'MsoListParagraphCxSpFirst',
  'MsoListParagraphCxSpMiddle',
  'MsoListParagraphCxSpLast',
];

export default (
  input: string,
  options: Options,
): string => {
  const msoListConverter = new MsoListConverter();
  const data = $(`<div>${input}</div>`);

  data.children().each(
    (i, parent) => applyRecursive(
      parent,
      (el) => {
        let target = new Target(el);

        // Extract blacklisted elements
        if (TAG_BLACKLIST.includes(target.tag)) {
          extractElement(target.$el);
          return;
        }

        // Replace slack gifs
        if (target.$el.hasClass('c-message_attachment__image')) {
          const matches = target.$el.css('backgroundImage').match(/url\("?(.+)"?\)/);
          if (matches !== null && matches.length >= 2) {
            const src = matches[1];
            target = replace(target.$el, `<img src="${src}">`);
          }
        }

        // Remove links if requested
        if (options.removeLinks && target.tag === 'a') {
          target = replace(target.$el, `<span>${target.$el.html()}</span>`);
        }

        // Remove images or slack emojis if requested
        if (target.tag === 'img') {
          const src = target.$el.attr('src');
          const isSlackEmoji = src && src.includes('slack-edge.com/production-standard-emoji-assets/');
          if (
            (isSlackEmoji && options.removeEmojis)
            || (!isSlackEmoji && options.removeImages)
          ) {
            target.$el.remove();
            return;
          }
        }

        // Remove timestamps if requested
        if (options.removeTimestamps) {
          const isTimestamp = target.$el.hasClass('c-timestamp') || target.$el.hasClass('c-message_list__day_divider__label');
          if (isTimestamp) {
            target.$el.remove();
            return;
          }
        }

        // Establish styles
        const { fontWeight, fontStyle, textDecoration } = el.style;
        const isBold = fontWeight === 'bold' || Number(fontWeight) >= 700;
        const isItalic = fontStyle === 'italic';
        const isUnderlined = textDecoration === 'underline';

        // Establish image dimensions
        const imgData = target.tag === 'img' ? {
          width: getImageDimension(target.el, 'width'),
          height: getImageDimension(target.el, 'height'),
        } : null;

        // Convert MS Word lists
        if (MSO_NAMES.includes(target.el.className)) {
          msoListConverter.convert(target.$el);
        }

        // Remove non-whitelisted attributes
        [...target.el.attributes].forEach(
          ({ name }) => {
            if (!ATTR_WHITELIST.includes(name)) {
              target.el.removeAttribute(name);
            }
          },
        );

        // Apply styles
        if (isBold) {
          wrapInner(target.$el, 'b');
        }
        if (isItalic) {
          wrapInner(target.$el, 'i');
        }
        if (isUnderlined) {
          wrapInner(target.$el, 'u');
        }

        // Apply image dimensions
        if (imgData !== null) {
          if (imgData.width !== null) {
            el.setAttribute('width', imgData.width);
          }
          if (imgData.height !== null) {
            el.setAttribute('height', imgData.height);
          }
        }
      },
    ),
  );

  return jQueryToString(data.children());
};
