
















































































import Vue from 'vue';
import Component from 'vue-class-component';

import CodeBlock from './CodeBlock.vue';
import Preview from './Preview.vue';
import Logo from './Logo.vue';
import simplify from '../lib/simplify';
import FeedbackForm from './FeedbackForm.vue';
import OptionsForm from './OptionsForm.vue';

@Component({
  components: {
    'code-block': CodeBlock,
    'feedback-form': FeedbackForm,
    logo: Logo,
    'options-form': OptionsForm,
    preview: Preview,
  },
})
export default class App extends Vue {
  $refs!: {
    feedbackForm: FeedbackForm,
  };

  error = null;

  hasError = false;

  input = '';

  options = {
    removeImages: false,
    removeEmojis: false,
    removeLinks: true,
    removeTimestamps: true,
  };

  get output(): string {
    return simplify(
      this.input,
      this.options,
    );
  }

  get hasOutput(): boolean {
    return this.output !== '';
  }

  async handlePaste(): Promise<void> {
    const items = await navigator.clipboard.read();
    let inputBlob = null;
    try {
      inputBlob = await items[0].getType('text/html');
    } catch {
      const textBlob = await items[0].getType('text/plain');
      inputBlob = new Blob(
        [`<div>${textBlob.text()}</div>`],
        { type: 'text/html' },
      );
    }

    if (inputBlob) {
      const input = await inputBlob.text();
      this.input = input;
    } else {
      console.error('Unable to read clipboard');
    }
  }

  async handleCopy(e: Event): Promise<void> {
    try {
      e.preventDefault();

      const outputBlob = new Blob(
        [this.output],
        { type: 'text/html' },
      );
      await navigator.clipboard.write([
        new ClipboardItem({
          'text/html': outputBlob,
        }),
      ]);
    } catch (err) {
      this.error = err.message;
    }
  }

  handleClear(): void {
    this.input = '';
  }

  toggleFeedbackForm(): void {
    this.$refs.feedbackForm.toggle();
  }
}
